:root {
  --primary-color: #e30d17;
}

html * {
  font-family: freight-text-pro, serif;
}

body {
  margin: 0;
}

#root {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

.btn {
  cursor: pointer;
  text-transform: uppercase;
  font-family: freight-text-pro, serif;
  font-size: 14px;
  font-weight: bold;
  background-color: #1d1e1c;
  padding: 16px 24px;
  color: #ffffff;
  text-decoration: none;
  margin-top: 24px;
  display: block;
  width: fit-content;
  border: none;
}

.btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.subtitle {
  font-weight: 400;
  margin-bottom: 24px;
  font-size: 24px;
}

h1 {
  font-family: lemongrass-script, sans-serif;
  font-size: 48px;
  margin-bottom: 48px;
}

.container {
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

header {
  margin-bottom: 80px;
  margin-top: 24px;
}

footer {
  background: #f4f4f4;
  padding: 16px 32px;
  margin-top: 80px;
}

.copyrights {
  text-align: center;
  margin: 0;
}

.input {
  border: 2px solid #707070;
  padding: 16px 20px;
  font-weight: bold;
  border-radius: 0;
  font-size: 14px;
  background-color: white;
  color: #707070;
  width: calc(100% - 44px);
  max-width: 500px;
}

.input:focus {
  border-color: #262626;
  border-radius: 0;
  outline: none;
  color: #262626;
}

.input::placeholder {
  color: #707070;
  font-family: freight-text-pro, serif;
}

.input:focus::placeholder {
  color: #262626;
}

.input-cont {
  margin-bottom: 24px;
}

.error {
  color: red;
}

.modal {
  position: fixed;
  z-index: 3;
  overflow: hidden;
  background: #00000080;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-in;
  pointer-events: none;
  opacity: 0;
}

.modal-title {
  font-family: lemongrass-script, sans-serif;
}

.modal-dialog {
  background: white;
  min-width: 270px;
  max-width: 600px;
  padding: 16px 24px;
  margin: auto;
}

.modal-btn {
  width: 100%;
}

.modal-desc {
  margin-bottom: 40px;
}

.show-modal {
  pointer-events: auto;
  opacity: 1;
}

.loader-section {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20000;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
}

@keyframes loader {
  0% {
    filter: none;
    filter: invert(18%) sepia(99%) saturate(4327%) hue-rotate(355deg)
      brightness(102%) contrast(129%);
  }
  100% {
    filter: none;
  }
}

.loader-section .logo-loader {
  width: 100px;
  height: 100px;
  object-fit: contain;
  animation: loader 1.5s infinite forwards;
}

.checkbox-cont {
  display: flex;
  align-items: center;
}

.checkbox-cont .checkbox {
  width: 15px;
  height: 15px;
  margin-right: 16px;
}

.close-block {
  display: flex;
  justify-content: flex-end;
}

.close-block .close-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.promo-code-header {
  margin-bottom: 40px;
  padding-top: 16px;
}

.promo-code-header-logo {
  width: 100px;
  margin-bottom: 32px;
}

.promo-code-header-content-container {
  padding-top: 40px;
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.promo-code-header-title {
  font-size: 36px;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top: 0;
}

.promo-code-header-slogan {
  font-size: 80px;
  color: var(--primary-color);
  margin-bottom: 16px;
  font-weight: 600;
  margin-top: 0;
}

.promo-code-header-code-img {
  width: 100%;
}

.promo-code-header-content {
  width: 60%;
}
.promo-code-header-code-img-box {
  width: 40%;
}

.promo-code-header-subtitle {
  margin-inline: 32px;
}

.promo-code-container {
  display: flex;
  align-items: center;
  gap: 32px;
}

.promo-code-box {
  padding-bottom: 80px;
}
.text-lg {
  font-size: 32px;
}

.promo-code-box-code-container {
  background-color: var(--primary-color);
  color: white;
  padding: 4px 80px;
  padding-bottom: 12px;
}
.promo-code-box-code {
  font-size: 36px;
  margin: 0;
}

.promo-code-box-button {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  max-width: none;
  transition: all 0.2s ease-in;
  color: black;
}
.promo-code-box-icon {
  height: 50px;
  width: 50px;
}

.promo-code-box-button path {
  transition: all 0.2s ease-in;
}

.promo-code-box-button:hover path {
  fill: var(--primary-color) !important;
}

.promo-code-box-button:hover {
  color: var(--primary-color);
}

.how-it-work {
  background: #f1f1f1;
  padding-block: 40px;
}

.how-it-work-content-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 80px;
  gap: 8px;
}

.promo-code-how-it-work {
  width: 25%;
  flex: 0.25;
}

.promo-code-how-it-work-icon-box {
  position: relative;
  border: 4px solid var(--primary-color);
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  padding: 10px;
}

.promo-code-how-it-work-background-icon-box {
  position: absolute;
  right: -10px;
  bottom: -10px;
  background: #f1f1f1;
  padding-top: 8px;
  padding-left: 8px;
}

.promo-code-how-it-work-icon {
  height: 40px;
  width: 40px;
}

.promo-code-how-it-work-content-text {
  font-size: 18px;
}

.app-shop-icon {
  height: 36px;
  display: block;
  margin-bottom: 16px;
}

@media only screen and (max-width: 990px) {
  .promo-code-header-code-img-box {
    width: 50%;
  }

  .promo-code-header-content {
    width: 50%;
  }

  .promo-code-header-slogan {
    font-size: 56px;
  }

  .promo-code-header-title {
    font-size: 28px;
    margin-bottom: 8px;
  }

  .text-lg {
    font-size: 24px;
  }
}

@media only screen and (max-width: 720px) {
  .promo-code-header-content-container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding-top: 0;
  }

  .promo-code-header-code-img-box {
    width: 80%;
  }

  .promo-code-header-content {
    width: 100%;
    text-align: center;
  }

  .promo-code-header-logo {
    display: none;
  }

  .promo-code-header-subtitle {
    margin-inline: 8px;
  }
  .promo-code-container {
    flex-direction: column;
    gap: 16px;
  }
  .how-it-work-content-container {
    flex-wrap: wrap;
    gap: 16px;
  }

  .promo-code-how-it-work {
    width: 50%;
    flex: 0.5;
    flex-basis: calc(50% - 16px);
  }
}

@media only screen and (max-width: 460px) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .promo-code-header-slogan {
    font-size: 48px;
  }

  .how-it-work-content-container {
    flex-wrap: wrap;
    gap: 56px;
  }

  .promo-code-how-it-work {
    width: 100%;
    flex: 1;
    flex-basis: 100%;
    display: flex;
    gap: 24px;
    align-items: baseline;
  }
}
